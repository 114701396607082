<template>
  <div class="contain">

  <div class="modalityCenter">
     <!-- <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="addGroup"
          >返回</el-button
        > -->
  <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      height="calc(100vh - 167px)"
      :cell-style="{textAlign:'center'}"
      :header-cell-style="{
          color: '#333',
          fontFamily: 'MicrosoftYaHeiUI',
          fontSize: '14px',
          fontWeight: 900,
          textAlign: 'center',
          background: '#f8f8f9',
        }"
      >
        <el-table-column
          type="index"
          label="序号"
          align="left"
          width="80"
        ></el-table-column>
        <el-table-column property="eventCodeName" label="事件类型" align="left">
          <template slot-scope="{ row }">
            <div :style="{ color: row.isRead == 2 ? 'red' : '#0C235B' }">
              {{
                row.eventCodeIdConfirmName
                  ? row.eventCodeIdConfirmName
                  : row.eventCodeName
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="eventResultsName"
          label="事件状态"
          align="center"
          width="90"
        >
          <template slot-scope="{ row }">
            <el-tag v-if="row.eventResults == 0" type="warning" size="mini">{{
              row.eventResultsName
            }}</el-tag>
            <el-tag v-if="row.eventResults == 1" type="success" size="mini">{{
              row.eventResultsName
            }}</el-tag>
            <el-tag v-if="row.eventResults == 2" type="warning" size="mini">{{
              row.eventResultsName
            }}</el-tag>
            <el-tag v-if="row.eventResults == 3" size="mini">{{
              row.eventResultsName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          property="eventAddressConfirm"
          label="地址"
          show-overflow-tooltip
          align="left"
        >
        <template slot-scope="{ row }">
          {{row.eventResults == 0 ? row.eventAddress : row.eventAddressConfirm}}
          </template>
        </el-table-column>
        <el-table-column
          property="createBy"
          label="创建人"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column property="eventPeopleName" label="巡线员" align="left">
          <template slot-scope="{ row }">
            {{
              row.eventResults == 1 && !row.eventPeopleName
                ? "--"
                : row.eventPeopleName
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="createTime"
          label="提交时间"
          align="left"
        ></el-table-column>
        <el-table-column
          property="checkingPoint"
          label="关联工单"
          align="center"
          width="90"
        >
          <template slot-scope="{ row }">
            <div
              v-if="row.eventDataId"
            >
              有
            </div>
            <div v-else type="info">无</div>
          </template>
        </el-table-column>
        <el-table-column
          property="checkingPoint"
          label="巡检点"
          align="center"
          width="90"
        >
          <template slot-scope="{ row }">
            <div v-if="row.checkingPoint == 0" type="info">否</div>
            <div v-if="row.checkingPoint == 1" type="success">是</div>
          </template>
        </el-table-column>
      <el-table-column align="left" prop="State" label="操作" width="260">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              @click="seeDetil(row)"
              v-if="$anthButtons.getButtonAuth('lookEvent')"
              size="mini"
              >详情</el-button
            >
            <el-button
              v-if="
                row.eventResults == 0 &&
                $anthButtons.getButtonAuth('deleteEvent')
              "
              type="danger"
              @click="delhandle(row)"
              size="mini"
              >删除</el-button
            >
            <el-dropdown
              @command="(command) => exchange(command, row)"
              v-if="row.eventResults != 0"
            >
              <el-button
                type="text"
                size="mini"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 15px;
                  height: 30px;
                  margin: 0 11px;
                "
                >更多</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="设置巡检点"
                  v-if="
                    row.eventResults != 0 &&
                    row.checkingPoint == 0 &&
                    $anthButtons.getButtonAuth('setEvent')
                  "
                  >设置巡检点</el-dropdown-item
                >
                <el-dropdown-item
                  command="取消巡检点"
                  v-if="
                    row.eventResults != 0 &&
                    row.checkingPoint == 1 &&
                    $anthButtons.getButtonAuth('cancelEvent')
                  "
                  >取消巡检点</el-dropdown-item
                >
                <!-- <el-dropdown-item
                  command="转维修"
                  v-if="
                    row.eventResults == 1 &&
                    row.isMaintain == 1 &&
                    $anthButtons.getButtonAuth('turnEvent')
                  "
                  @click="maintain(row)"
                  >转维修</el-dropdown-item
                > -->
                 
                <el-dropdown-item
                  command="设为已处理"
                 v-if="
                    (row.eventResults == 1 || row.eventResults == 2) &&
                    $anthButtons.getButtonAuth('disposeEvent')
                  "
                  >设为已处理</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paramsData.current"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="paramsData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="eventstotal"
      >
      </el-pagination>
    </div>
  </div>
   
    <!-- 事件详情 -->
    <el-dialog width="40%" title="详情" :visible.sync="detilVisible">
      <!-- 平台添加的待确认的事件详情 -->
      <!-- eventResults	事件状态：0：待确认，1：已确认，2：已关闭 -->
      <el-descriptions
        title=""
        :column="1"
        :labelStyle="{ width: '80px' }"
        v-if="!detiltable.taskId"
      >
        <el-descriptions-item label="事件类型">{{
          detiltable.eventCodeName
        }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{
          detiltable.eventAddress
        }}</el-descriptions-item>
        <el-descriptions-item label="巡线员">{{
          detiltable.eventPeopleName
        }}</el-descriptions-item>
        <el-descriptions-item label="上报人电话">{{
          detiltable.eventPlone
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          detiltable.eventRemark
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          detiltable.createTime
        }}</el-descriptions-item>
      </el-descriptions>
      <!-- 巡线员已确认的事件详情 -->
      <span v-if="detiltable.eventCodeIdConfirmName && !detiltable.taskId">
        <el-divider v-if="detiltable.eventSource != 2"></el-divider>
        <el-descriptions title="" :column="1" :labelStyle="{ width: '80px' }">
          <el-descriptions-item label="事件类型">{{
            detiltable.eventCodeIdConfirmName
          }}</el-descriptions-item>
          <el-descriptions-item label="详细地址">{{
            detiltable.eventAddressConfirm
          }}</el-descriptions-item>
          <el-descriptions-item
            label="巡线员"
            v-if="detiltable.eventSource == 1"
            >{{ detiltable.eventPeopleName }}</el-descriptions-item
          >
          <el-descriptions-item label="说明">{{
            detiltable.eventDescribe
          }}</el-descriptions-item>
          <el-descriptions-item label="图片">
            <div
              v-for="(item, index) in detiltable.imgUrlArr"
              :key="index"
              style="float: left; margin-left: 10px; border: 1px solid #ecedf1"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="detiltable.imgUrlArr"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="附件" v-if="detiltable.pid == 112">
            <span
              v-for="(item, i) in detiltable.fileUrlArr"
              :key="i + 'file34'"
            >
              <el-tooltip
                class="item"
                effect="light"
                :content="item"
                placement="top"
              >
                <el-link :underline="false" :href="item" target="_blank"><span class="content-text">{{ item }}</span></el-link>
              </el-tooltip>
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{
            detiltable.confirmTime
          }}</el-descriptions-item>
        </el-descriptions>
      </span>
      <!-- 巡线员巡检过程中提交的事件详情 -->
      <div v-if="detiltable.taskId">
        <el-descriptions title="" :column="1" :labelStyle="{ minWidth: '80px' }">
          <el-descriptions-item label="事件类型">{{
            detiltable.eventCodeIdConfirmName
          }}</el-descriptions-item>
          <el-descriptions-item label="详细地址">{{
            detiltable.eventAddressConfirm
          }}</el-descriptions-item>
          <el-descriptions-item label="巡线员">{{
            detiltable.eventPeopleName
          }}</el-descriptions-item>
          <el-descriptions-item label="说明">{{
            detiltable.eventDescribe
          }}</el-descriptions-item>
          <el-descriptions-item label="图片">
            <div
              v-for="(item, index) in detiltable.imgUrlArr"
              :key="index"
              style="float: left; margin-left: 10px; border: 1px solid #ecedf1"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="detiltable.imgUrlArr"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="附件" v-if="detiltable.pid == 112">
            <span
              v-for="(item, i) in detiltable.fileUrlArr"
              :key="i + 'file34'"
            >
              <el-tooltip
                class="item"
                effect="light"
                :content="item"
                placement="top"
              >
                <el-link :href="item" target="_blank"><span class="content-text">{{ item }}</span></el-link>
              </el-tooltip>
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">{{
            detiltable.confirmTime
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <!-- 设为已处理 -->
      <div v-if="detiltable.eventResults == 3">
        <el-descriptions title="" :column="1" :labelStyle="{ width: '80px' }">
          <el-descriptions-item label="已处理人">{{
            detiltable.handler
          }}</el-descriptions-item>
          <el-descriptions-item label="已处理时间">{{
            detiltable.handleTime
          }}</el-descriptions-item>
        <el-descriptions-item label="已处理备注">{{
            detiltable.handleRemark
          }}</el-descriptions-item>
          <el-descriptions-item label="已处理图片" v-if="detiltable.handleImgUrl">
            <div
              v-for="(item, index) in detiltable.handleImgUrl.split(',')"
              :key="index"
              style="float: left; margin-left: 10px; border: 1px solid #ecedf1"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="detiltable.handleImgUrl.split(',')"
              >
              </el-image>
            </div>
          </el-descriptions-item>
          </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detilVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择维修人员 -->
    <el-dialog
      title="提交维修工单"
      :visible.sync="showeventDialog"
      :show-close="true"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form ref="texture" :model="texture" label-width="120px" :rules="rule">
        <el-form-item label="受理部门：" prop="deptId">
          <el-cascader
                v-model="texture.deptId"
                :options="sectionList"
                :props="TreeProps"
                size="mini"
                style="width: 100%"
                :show-all-levels="false"
                ref="cascaderHandle"
                @change="selectDepartment"
            >
              <span slot-scope="{data}">
                  {{data.name}}
              </span>
            </el-cascader>
        </el-form-item>
        <el-form-item label="受理人：" >
          <el-select
            clearable
            size="mini"
            filterable
            style="width: 100%"
            v-model="texture.userId"
            placeholder="请选择受理人"
          >
            <el-option
              v-for="item in maintainUserList"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优先级：" prop="priorLevel">
          <el-radio-group v-model="texture.priorLevel">
            <el-radio :label="item.id" v-for="(item,index) in priorityList" :key="index">{{item.priorLevel}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showeventDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitService('form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-dialog
        :visible.sync="showModel"
        :show-close="false"
        :close-on-click-modal="false">
      <mapDialog  @closeModal="closeModel"></mapDialog>
    </el-dialog> -->
    <!-- <el-dialog
        title="选择事件类型"
        :visible.sync="showeventDialog"
        :show-close="true"
        :close-on-click-modal="false"
        @closed="showIndex=null">
      <el-collapse accordion >
        <el-collapse-item v-for="item in eventTypeList" :title="item.name" :key="item.id" >
          <div :class="showIndex==person.id?'activeClass':'noactiveClass'" style="" v-for="(person,index) in item.children" :key="person.id" @click="clickNode(item.name,person)">{{index+1}}-{{person.name}}</div>
        </el-collapse-item>
      </el-collapse>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showeventDialog = false">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- 设为已处理 -->
    <el-dialog
      title="设为已处理"
      :visible.sync="showprocessed"
      width="25%"
      :close-on-click-modal="false"
      @close="closeshowprocessed('processedform')"
    >
    <el-form ref="processedform" :model="processedform" label-width="90px" :rules="rule">
      <el-form-item label="图片:">
        <uploadfile :fileData="{}" ref="uploadfile"></uploadfile>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="processedform.handleRemark"
            type="textarea"
            maxlength="200"
            name="limitLength"
            placeholder="请输入备注"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showprocessed = false">取 消</el-button>
        <el-button type="primary" @click="submitprocessed('processedform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ByPostpeople,
  closeevent,
  deleteBatchevent,
  dicByType,
  eventPage,
  zypatrolGetUserList,
  patrolGetDict,
  getTimeoutList,
  setEvent,
  selectAllUsersByDeptId,
  maintainEvent,
  eventPagedetil, saveevent,excelEvents,
  setcheckpoint, uploadImg,markEvent,eventMaintain
} from "@/RequestPort/maintenance";
import uploadfile from '@/components/uploadFile.vue'
import {
  selectSysDeptTreeList
} from '../../RequestPort/user/user'
// import mapDialog from './mapDialog'
import { geoOws } from '@/apis/geo'
export default {
  name: "index",
  // components:{mapDialog}
  components: {uploadfile },
  watch:{
    'form.eventResults'(e){
      if(e == 1){
        this.radioDisable = true
        this.form.eventPeopleIds = ''
      }else{
        this.radioDisable = false
      }
    }
  },
  data(){
    return{
      radioDisable:false,
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList:[],
      showIndex:null,
      showModel:false,
      // 详情
      detilVisible:false,
      showAddModel:false,
      showeventDialog:false,
      paramsData:{
        current:1,
        size: 100,
        searchText:'',
        eventStatus:'',
        checkpoint:'',
        eventPeopleIds:'',
        ids:this.$route.query.eventIds,
      },
      form:{
        eventCodeName:'',
        eventAddress:'',
        eventResults:'0',
        detectionConcentrationUnit:'ppm',
        confirmConcentrationUnit:'ppm',
        locations: [],
        eventPeopleIds: '',
        eventPeoples:[],
        urls:[],

      },
      rule:{
        eventCodeName:[{ required: true, message: '请选择事件类型', trigger: 'change' }],
        eventAddress:[{ required: true, message: '请选择地址', trigger: 'change' }],
        eventPeopleIds:[{ required: true, message: '请选择报送人', trigger: 'change' }],
        deptId:[
          { required: true, message: "请选择受理部门",  trigger: ["blur", "change"], },
        ],
        priorLevel:[
          { required: true, message: "请选择优先级", trigger: "change" },
        ]
      },
      detiltable:{
        confirmedUrls:[]
      },
      sourceStatus:[{label:'是',id:'1'},{label:'否',id:'0'}],
      unit:['ppm','vol%'],
      userStatus:[{label:'待确认',id:'0'},{label:'已确认',id:'1'},{label:'已完成',id:'2'}],
      jianlouList:[{label:'已排除',id:'1'},{label:'已确认',id:'2'},{label:'待确认',id:'0'}],
      DataPicker:[],
      tableData:[],
      eventTypeList:[],
      eventPeopleIds:[],
      peopleList:[],
      eventstotal:0,
      multipleSelection:[],
      // 事件提交维修
      texture: {
        priorLevel:'3'
      },
      // 事件类型
      eventTypeIdList: [],
      // 受理部门
      sectionList:[],
      // 优先级
      priorityList:[],
      // 受理人员
      maintainUserList:[],
      TreeProps:{
        label: 'name',
        value:'id',
        children:'children',
        emitPath:false,
        checkStrictly: true
      },
      // 设为已处理弹窗
      showprocessed:false,
      processedform:{}
    }
  },
  methods:{
    deriveExcel(){
      let ids = []
      this.multipleSelection.forEach((item,index)=>{
        ids.push(item.id)
      })
      excelEvents({checkpoint:this.paramsData.checkpoint,ids:ids.toString(),
        eventStatus:this.paramsData.eventStatus,
        searchText:this.paramsData.searchText,
        eventPeopleIds:this.paramsData.eventPeopleIds}).then((e)=>{
          window.open(e.data)

      })
    },
    EventStatusChange(e){
    },
    repairBeforUploadFun(e){
      this.repairBeforFileFormData.append('file',e.file)
      uploadImg(this.repairBeforFileFormData).then((res)=> {
        console.log('res',res)
        this.form.urls.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
    },
    beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    beforhandleRemove(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileList,file);
      this.form.urls.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    addGroup(){
      // this.showAddModel = true
      this.$router.go(-1)
      // ByPostpeople({code:'managerPerson'}).then(res=>{
      //   this.peopleList = res.data
      // })
    },
    closeeventDialog(form){
      this.form={
        eventCodeName:'',
        eventAddress:'',
        eventResults:'0',
        detectionConcentrationUnit:'ppm',
        confirmConcentrationUnit:'ppm',
        eventPeopleIds:'',
        eventPeoples:[],
        urls:[]
      }
      this.$refs[form].resetFields()
      this.$refs.upload.clearFiles()
      this.eventPeopleIds = []
    },
    clickNode(item,person){
      this.showIndex = person.id
      this.form.eventCodeId = person.id
      this.form.eventCodeName = item + '->'+person.name
      this.$forceUpdate()
    },
    // showEventType(){
    //   dicByType({parentCode:'GAS_PATROL_EVENT',level:2}).then(res=>{
    //     if(res.code===200){
    //       this.eventTypeList = res.data
    //       this.showeventDialog = !this.showeventDialog
    //     }
    //   })
    //   // patrolGetDict({dictType: 3}).then(res => {
    //   //     this.eventTypeList = res.data
    //   //     this.showeventDialog = !this.showeventDialog
    //   // })
    // },
    /**
     * 提交维修
     */
    submitService() {
      this.$refs.texture.validate((valid) => {
        if (valid) {
          eventMaintain(this.texture).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.showeventDialog = false;
              maintainEvent(this.texture.relationId)
              this.loadeventslist(this.paramsData);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      })
    },
    /**
     * 关闭设为已处理弹窗
     */
    closeshowprocessed(){
      this.showprocessed = false
      if(this.$refs.uploadfile){
        this.$refs.uploadfile.setImgKeyList()
      }
    },
    /**
     * 设为已处理确认事件
     */
    submitprocessed(){
      this.getsetEvent()
    },
    /**
     * 设为已处理
     */
    getsetEvent(row) {
      let that = this;
      this.processedform.handleImgUrl = this.$refs.uploadfile.getImgKeyList().join(',')
      let data = {...this.processedform}
      this.$confirm(`是否将此事件设为已处理状态?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        setEvent(data).then((res) => {
          if (res.code == 200) {
            that.$message.success("设置成功");
            that.showprocessed = false
            if(this.$refs.uploadfile){
              this.$refs.uploadfile.setImgKeyList()
            }
            markEvent(data.id).then(() => {
              setTimeout(() => {
                that.loadeventslist(that.paramsData);
              }, 500);
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    /**
     * 操作更多功能
     */
    exchange(command, row) {
      if (command == "设置巡检点" || command == "取消巡检点") {
        this.del(row);
      }
      if (command == "转维修") {
        this.maintain(row);
      }
      if (command == "设为已处理") {
        // this.getsetEvent(row);
        this.showprocessed = true
        this.processedform = {}
        if(this.$refs.uploadfile){
          this.$refs.uploadfile.setImgKeyList()
        }
        if (this.$refs.processedform) {
          this.$refs.processedform.resetFields()
        }
        this.processedform.id = row.id
      }
    },
    /**
     * 点击维修
     */
    maintain(row) {
      markEvent(row.id).then(() => {
        setTimeout(() => {
          this.loadeventslist(this.paramsData);
        }, 500);
      });
      this.texture.relationId = row.id;
      this.showeventDialog = true;
      // 部门下拉
      selectSysDeptTreeList({deptListShow:'n'}).then((e)=>{
        let tree=e.data
        if(tree[0]){
          tree[0].disabled=true
        }
        // 特殊处理
        if(tree[0].children) {
          tree[0].children.forEach(el => {
            if(el.id == 8) {
              el.disabled=true
            }
          });
        }
        this.sectionList = tree
      })
      // 优先级
      getTimeoutList().then(res => {
        this.priorityList = res.data
      })
      if(this.$refs.texture) {
      this.$refs.texture.resetFields();
      }
    },
    /**
     * 选择部门
     */
    selectDepartment(val){
      console.log(val)
      // 通过部门id查此部门及其下所有部门用户（人员下拉）
      selectAllUsersByDeptId({deptId:val,code:'aj006'}).then(res => {
        console.log(res)
        this.maintainUserList = res.data
      })
    },
    submitAdd(form){
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.form.eventCodeId){
            if(this.form.eventAddress){
              this.getNetwork()
              // if(this.eventPeopleIds.length>0){
              //   let eventPeoples = []
              //   let eventPeopleIds = []
              //   this.eventPeopleIds.forEach(item=>{
              //     eventPeoples.push(item.userName)
              //     eventPeopleIds.push(item.userId)
              //   })
              //   this.form.eventPeoples= eventPeoples.toString()
              //   this.form.eventPeopleIds= eventPeopleIds.toString()
              // }


              // saveevent(this.form).then(res=>{
              //   if(res.code===200){
              //     this.showAddModel = false
              //     this.$message.success(res.msg)
              //     this.loadeventslist(this.paramsData)
              //   }
              // })
            }else {
              this.$message.error('请选择地址')
            }
          }else {
            this.$message.error('请选择事件类型')
          }
        }
      })
    },
     getNetwork(){
      let that=this
      let file=this.getfile(this.form.locations)     
      let parames={
          service:'WFS',
          request:'GetFeature',
          typeName:'gas:patrol_networks',
          outputFormat:'application/json',
          version:'1.0.0',
          filter:file
      }
      
      geoOws(parames).then(res => {
        let features=res.features
        if(features&&features.length>0){
          let ids=[]
          features.forEach(item => {
             ids.push(item.properties.id)
          });
         this.form.networkIds=ids.toString()
        }  
     
        that.saveForm()
           
         
      })
    },
    saveForm(){
        saveevent(this.form).then(res=>{
                if(res.code===200){
                  this.showAddModel = false
                  this.$message.success(res.msg)
                  this.loadeventslist(this.paramsData)
                }
              })
    },
    getfile(lnglat){
      let file='<Filter xmlns:gml="http://www.opengis.net/gml">'+
                       ' <And>   <PropertyIsGreaterThan>'+
                        '  <PropertyName>general_status</PropertyName>'+
                         ' <Literal>0</Literal>'+
                        ' </PropertyIsGreaterThan> <Intersects>'+
                        ' <PropertyName>location</PropertyName>'+
                      '   <gml:Point>'+
                            '  <gml:coordinates>'+lnglat[0]+','+lnglat[1]+'</gml:coordinates>'+
                        ' </gml:Point>'+
                    '  </Intersects> </And>'+
                '  </Filter>'            
      return file
    },
    closeModel(address,lnglat){
      if (!address && !lnglat) {
        this.showModel = false
      } else {
        this.form.locations = lnglat
        this.form.eventAddress = address
        this.showModel = false
        this.$forceUpdate()
      }
    },
    del(row){
      let arr =  row.checkingPoint==0?'设置巡检点':'取消巡检点'
      let type = row.checkingPoint==0?'1':'0'
      this.$confirm(`是否${arr}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        setcheckpoint({id:row.id,setType:type}).then(res=>{
          this.$message({
            message: '设置成功',
            type: 'success'
          });
          this.paramsData.current = 1
          this.loadeventslist(this.paramsData)
        })
      })
    },
    handle(row) {
      this.$confirm(`是否关闭事件?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        closeevent({id:row.id}).then(res=>{
          this.$message({
            message: '关闭成功',
            type: 'success'
          });
          this.paramsData.current = 1
          this.loadeventslist(this.paramsData)
        })
      })
    },
    // 详情
    seeDetil(row){
      markEvent(row.id).then(() => {
        this.loadeventslist(this.paramsData);
      });
      if (row.imgUrl) {
        row.imgUrlArr = row.imgUrl.split(",");
      } else {
        row.imgUrlArr = [];
      }
      if (row.fileUrl) {
        row.fileUrlArr = row.fileUrl.split(",");
      } else {
        row.fileUrlArr = [];
      }
      this.detiltable = row;
      this.detilVisible = !this.detilVisible;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    delhandle(row){
       markEvent(row.id);
      this.$confirm(`是否删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          arr.push(row.id);
          deleteBatchevent({ ids: arr }).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              // this.paramsData.current = 1
              this.loadeventslist(this.paramsData);
            } else {
              this.loadeventslist(this.paramsData);
              this.$message({
                message: "删除失败",
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.loadeventslist(this.paramsData);
        });
    },
    search(){
      if(this.DataPicker&&this.DataPicker.length>0){
        this.paramsData.startTime =  this.DataPicker[0]
        this.paramsData.endTime =  this.DataPicker[1]
      }else{
        this.paramsData.startTime =  null
        this.paramsData.endTime =  null
      }
      this.paramsData.current = 1
      this.loadeventslist(this.paramsData)
    },
    resize(){
      this.paramsData={
        current:1,
        size: 100,
        searchText:'',
        eventStatus:'',
        checkpoint:''
      }
      this.DataPicker = []
      this.loadeventslist(this.paramsData)
    },
    handleCurrentChange(val){
      this.paramsData.current=  val
      this.loadeventslist(this.paramsData)
    },
    handleSizeChange(val){
      this.paramsData.size=  val
      this.loadeventslist(this.paramsData)
    },
    loadeventslist(obj){
      eventPage(obj).then((res) => {
        this.tableData = res.data.records
        this.eventstotal = res.data.total
      })
    },
  },
  mounted() {
    this.paramsData.ids = this.$route.query.id
      // zypatrolGetUserList({code:'managerPerson'}).then(res=>{
      //   this.peopleList = res.data
      // })
    this.loadeventslist(this.paramsData)
  }
}
</script>

<style scoped lang='less'>
.item {
  color: #169bd5;
}
.activeClass{
  background-color: #F56C6C;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px;
  margin-left:20px
}
/*.noactiveClass:hover{*/
/*  background-color: #F56C6C;*/
/*  color: #FFFFFF;*/
/*  cursor: pointer;*/
/*}*/
.noactiveClass{
  padding: 10px;
  margin-left:20px
}
.contain{
  background-color:transparent;
  margin: 0;
}
// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  .crumbs{
    padding: 10px 15px 20px;
    span{
      color: #3069E1;
    }
  }
}
// 搜索
.searchForm{
  display: flex;
   flex-wrap: wrap;
    // justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 10px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
}
/deep/.el-descriptions-item__content{
  flex-wrap: wrap;
}
</style>
